<script>
export default {
  methods: {
    // 加入购物车
    joinShopCar($event,e){
      $event.stopPropagation();
      // this.feightPriceText == "不配送"
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      this.$ajax({
				url:'/hxclass-pc/pc-mall/shop/save',
				method:'post',
				params:{
					count:1, // 数量
					couponCode:'', // 优惠劵编码
					openTimeId:'', // 开班设置id
					price:e.couponPrice, // 价格  优惠价格-还没有计算优惠券
					productId:e.productId, // 商品id
					type:e.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
					userNo:this.$store.state.userInfo.userId, // 用户
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
          this.$refs['sidebar'].getShopList()
					return this.$message.success('添加购物车成功');
				}else{
					this.$message.error(res.message)
				}
      })
    },
  }
}
</script>