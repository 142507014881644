<template>
  <div class="sidebar">
    <div class="betterScroll" v-show="$route.path == '/knowledgeMall/shopHome' && !retract">
      <p class="name" :class="{active: activeIndex == index}" @click="scrollTopFun(index)" v-for="(item,index) in scrollTopList" :key="item">{{ modelName[index] }}</p>
      <div class="retract" @click="retract = true">
        <p>收起</p>
        <img src="@/assets/image/RevisionHome/retractIcon.png" alt="">
      </div>
    </div>
    <!-- 收起状态 -->
    <div class="retractStyle" @click="retract = false" v-show="retract"><a-icon class="aIcon" type="left" /></div>

    <div class="elseOption">
      <div class="shopCar" @click="goShopCar" v-if="$store.state.userInfo.userId">
        <a-badge :count="shopCarNum" :offset="[10, -5]" :numberStyle="{transform: 'scale(0.6)'}" :overflowCount="99">
          <a-icon class="icon" type="shopping-cart" />
        </a-badge>
        <p class="text">购物车</p>
      </div>
      <div class="servicePeople">
        <img class="icon" src="@/assets/image/RevisionHome/serviceIcon2.png" alt="">
        <p class="text">联系客服</p>
        <div class="qrCode">
          <img src="@/assets/image/nav/banner_person.png" alt="">
          <p>扫一扫</p>
          <p>添加微信客服</p>
        </div>
        <div class="box"></div>
      </div>
      <div class="backTop" v-if="scrollTop > 1000">
        <a-back-top>
          <a-icon class="aIcon" type="up-circle" />
          <p class="text">回顶部</p>
        </a-back-top>
      </div>
    </div>

    <div class="pageTab" v-show="$route.path == '/'">
      <div class="item" v-for="item in pageTab" :key="item.name" @click="scrollToPage(item.scrollTop)">
        <img :src="item.imgSrc" alt="">
        <div class="name" :style="{color: item.color}">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      scrollTop: 0, // 滚动高度
      shopCarNum: 0, // 购物车数量
      couponList: [
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        },
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        },
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        },
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        }
      ],

      activeIndex: 0, // 选中下标
      scrollTopList: [], // 侧边栏滚动距离
      retract: false, // 是否收起
      modelName: ['课程推荐','精品课程','主编力荐','套餐推荐','华夏图书','精品教具'], // 模块名称
      
      pageTab: [
        {
          name: '课程专区',
          color: "#3BD2AE",
          imgSrc: require("@/assets/image/RevisionHome/pageTab1.png"),
          scrollTop: 0
        },
        {
          name: '公益科普',
          color: "#4071ED",
          imgSrc: require("@/assets/image/RevisionHome/pageTab2.png"),
          scrollTop: 1250
        },
        {
          name: '图书教具',
          color: "#FF8845",
          imgSrc: require("@/assets/image/RevisionHome/pageTab3.png"),
          scrollTop: 2140
        }
      ],
      pointLoad: false,
    }
  },
  created(){
    if(this.$store.state.userInfo.userId){
      this.getShopList();
    }
    if(this.$route.path == '/') {
      this.modelName = ['重磅课程', '热门课程', '免费课程', '孤独症之声', '科普视频', '图书教具', '资讯速递']
    }
  },
  mounted() {
    setTimeout(() => {
      let arr = document.querySelectorAll('.module-inner');
      let scrollHeight = [];
      for(let i=0;i<arr.length;i++) {
        if(this.$route.path == '/') {
          scrollHeight.push(arr[i].scrollHeight - 20);
        }else{
          scrollHeight.push(arr[i].scrollHeight + 30);
        }
        let scrollTop = [];
        scrollHeight.map((item)=>{
          if(scrollTop.length) {
            scrollTop.push(item + scrollTop[scrollTop.length-1])
          }else{
            scrollTop.push(item)
          }
        })
        this.scrollTopList = scrollTop;
      }
    },500)
    
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods:{
    scrollTopFun(e) {
      this.activeIndex = e;
      this.pointLoad = true;
      let offsetTop = document.querySelector("#div" + e).offsetTop - 70;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth"
      })
      clearTimeout(this.tiemID1);
      this.tiemID1 = setTimeout(() => {
        this.pointLoad = false;
      }, 500);
    },
    scrollToPage(e) {
      window.scrollTo({
        top: e,
        behavior: "smooth"
      })
    },
    // 跳转购物车
    goShopCar() {
      if(this.shopCarNum === 0){
        if(this.$route.path == "/shopBar"){
          this.$router.push('/knowledgeMall/shopHome');
        }
        return this.$message.error('请添加商品');
      }
      this.$router.push('/shopBar')
    },
    // 返回顶部
    backTop() {
      document.documentElement.scrollTop = 0;
    },
    // 获取页面滚动距离
    handleScroll (e) {
      if (this.pointLoad) return;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop;
            this.scrollTopList.some((item,index)=>{
        let e = 5;
        if(index == 0) {
          if(this.scrollTop <= item + e) {
            clearTimeout(this.tiemID1);
            this.tiemID1 = setTimeout(() => {
              this.activeIndex = index;
            }, 100);
            return true;
          }
        }else {
          if(this.scrollTop <= item + e && this.scrollTop > this.scrollTopList[index-1] + e) {
            // this.activeIndex = index;
            clearTimeout(this.tiemID1);
            this.tiemID1 = setTimeout(() => {
              this.activeIndex = index;
            }, 100);
            return true;
          }
        }
      })
    },
    // 获取购物车列表
    getShopList() {
      this.$ajax({
        url: '/hxclass-pc/pc-mall/shop/list',
        method: 'get'
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data
          let num = 0;
          res.data.map(item=>{
            num += item.count;
          })
          this.shopCarNum = num;
        }
      })
    }
  },
  // 滚动重置
  beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped lang="less">
  .betterScroll{
    width: 68px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E2E2E2;
    position: absolute;
    right: 16px;
    top: 200px;
    padding: 12px 0;
    .name{
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin-bottom: 8px;
      cursor: pointer;
    }
    .active{
      color: #15B7DD;
      background: rgba(21,183,221,0.1);
    }
    .retract{
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      p{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-right: 9px;
      }
      img{
        width: 10px;
        height: 10px;
      }
    }
  }
  .retractStyle{
    width: 20px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 5px 0px 0px 5px;
    position: absolute;
    right: 0;
    top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .pageTab{
    width: 68px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E2E2E2;
    position: absolute;
    right: 16px;
    top: 200px;
    padding: 8px 0;
    >div{
      position: relative;
    }
    >div::after{
      content: '';
      width: 36px;
      height: 1px;
      background: rgba(196,196,196,0.5);
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
    }
    >div:last-child::after{
      height: 0;
    }
    .item{
      opacity: 0.6;
      margin-bottom: 17px;
      cursor: pointer;
      img{
        height: 21px;
      }
      .name{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        line-height: 18px;
        margin-top: 4px;
      }
    }
    .item:last-child{
      margin: 0;
    }
    .item:hover{
      opacity: 1;
    }
  }
  .elseOption{
    width: 68px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E2E2E2;
    position: absolute;
    right: 16px;
    top: 432px;
    padding: 8px 0;
    >div{
      position: relative;
      // transition: all 0.4s ease;
      // .text,.icon,.aIcon,img{
      //   transition: all 0.4s ease;
      // }
    }
    >div::after{
      content: '';
      width: 36px;
      height: 1px;
      background: rgba(196,196,196,0.5);
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
    }
    >div:last-child::after{
      height: 0;
    }
    // >div:hover{
      // .text{
      //   color: @theme;
      // }
      // .icon,.aIcon,img{
      //   transform: scale(1.1);
      //   color: @theme;
      // }
    // }
    .servicePeople:hover{
      .qrCode{
        display: block;
      }
      .box{
        display: block;
      }
    }
  }
  .sidebar{
    position: fixed;
    right: 0;
    top: 60px;
    z-index: 99;
    padding-top: 20vh;
    div{
      text-align: center;
    }
    .backTop{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      .aIcon{
        font-size: 22px;
        color: #333333;
      }
      .text{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-top: 4px;
      }
      .ant-back-top{
        position: initial;
      }
    }
    .servicePeople{
      position: relative;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 20px;
      }
      .text{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-top: 4px;
      }
      .couponList{
        width: 290px;
        height: 450px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(93,131,167,0.1500);
        position: absolute;
        left: -290px;
        top: -200px;
        display: none;
        padding: 24px;
        .couponTitle{
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 18px;
          text-align: left;
        }
        .couponItem{
          display: flex;
          background-image: url('~@/assets/image/knowledgeMall/couponBack.png');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 242px;
          height: 80px;
          margin-top: 16px;
          padding-top: 12px;
          .left{
            width: 92px;
            .couponPrice{
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 300;
              color: #3A3330;
              margin-top: 12px;
              text-align: left;
              span{
                font-size: 30px;
                font-weight: bold;
              }
            }
            .condition{
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #3A3330;
              line-height: 15px;
              padding-left: 10px;
              text-align: left;
            }
          }
          .right{
            flex: 1;
            padding-top: 3px;
            .couponName{
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 14px;
              text-align: left;
              margin-left: -10px;
            }
            .time{
              margin-left: -20px;
              width: 100px;
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 8px;
              margin-top: 7px;
              text-align: left;
              white-space: nowrap;
              transform: scale(0.7);
            }
            .type{
              margin-top: 8px;
              font-size: 10px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #842C17;
              line-height: 10px;
              text-align: left;
            }
          }
        }
      }
      .qrCode{
        width: 128px;
        height: 178px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(93,131,167,0.1500);
        position: absolute;
        left: -148px;
        top: -69px;
        display: none;
        padding: 14px;
        img{
          width: 100px;
          height: 100px;
          margin-bottom: 8px;
        }
        p{
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          text-align: center;
        }
      }
      .box{
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-left: 4px solid #FFFFFF;
        border-bottom: 4px solid transparent;
        position: absolute;
        left: -20px;
        top: 14px;
        display: none;
      }
    }
    .orderIcon{
      height: 31px;
      padding: 7px 0;
      margin-top: 14px;
      position: relative;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 20px;
      }
      .title{
        width: 92px;
        height: 32px;
        box-sizing: border-box;
        position: absolute;
        left: -0px;
        top: 0;
        border-radius: 3px 0px 0px 3px;
        border: 1px solid #C4C4C4;
        border-right: 0;
        line-height: 29px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        background: #FFFFFF;
        display: none;
      }
    }
    .orderIcon:hover{
      background: #15B7DD;
      .title{
        display: block;
        left: -92px;
      }
    }
    .shopCar{
      margin-bottom: 24px;
      cursor: pointer;
      .icon{
        font-size: 21px;
        color: #333333;
      }
      .text{
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-top: 4px;
      }
    }
    .backTop,
    .servicePeople,
    .shopCar{
      opacity: 0.6;
    }
    .backTop:hover,
    .servicePeople:hover,
    .shopCar:hover{
      opacity: 1;
    }
  }
</style>